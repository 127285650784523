import { useGlobal } from "src/stores/landingpage/global.store";

export const useVideo = () => {
    const videoURL = '/assets/videos/newhome.mp4';
    const loopVideoUrl = '/assets/videos/newhome.mp4';
    const { setIsFirstVideo } = useGlobal(e => e)

    const handleVideoEnd = () => {
      // setar isFirstVideo para false no store (zustand)
      setIsFirstVideo(false)
    }
  
    return {
      videoURL,
      loopVideoUrl,
      handleVideoEnd
    }
  
  };
  