import { useEffect } from 'react';

import Router from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import 'src/global.css';
import 'src/locales/i18n';
import ThemeProvider from 'src/theme';

import ProgressBar from 'src/components/progress-bar';
import { SettingsProvider } from 'src/components/settings';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { useAuth } from './auth/auth.hook';
import { LocalizationProvider } from './locales';
import { SnackbarProvider } from './components/snackbar';

const firebaseConfig = {
  apiKey: 'AIzaSyDKSL4Syia66zthlrA-yMgV4ae5tA1AtZE',
  authDomain: 'roxcode-site.firebaseapp.com',
  projectId: 'roxcode-site',
  storageBucket: 'roxcode-site.appspot.com',
  messagingSenderId: '1004546224429',
  appId: '1:1004546224429:web:b5f29354463c4dc66723a4',
  measurementId: 'G-HCNJP3J33X',
};

export default function App() {
  // useScrollToTop();

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <LocalizationProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'dark', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'horizontal', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <ProgressBar />
              <Router />
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
